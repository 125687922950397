import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { Ficha } from 'projects/senai-pr/src/app/class/ficha';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ficha-reporte',
  templateUrl: './ficha-reporte.component.html',
  styleUrls: ['./ficha-reporte.component.scss']
})
export class FichaReporteComponent implements OnInit {
  @Input() fichaTemp:Ficha;
  @Output() cerrar = new EventEmitter();
  ficha:Ficha;
  data=new Array();
  loaded= false;
  lastWindowsSize = 0;
  mensaje = "";
    public pieChart: GoogleChartInterface = {
      chartType: 'PieChart',
      dataTable: this.data,
      options: {
        title: 'Resultados',
        animation: {
          duration: 900,
          startup: true,
          easing: 'out',
        },
      },
      refreshInterval:2,
    };

  constructor( private translate:TranslateService) { }

  ngOnInit(): void {
    this.ficha = {...this.fichaTemp};
    this.consejo();
    this.dataChart();
  }

  dataChart(){
    let correctas = 0;
    let incorrectas = 0;
    _.each(this.ficha.alternativas, (alternativa)=>{
      (alternativa.correcta==="SI")?correctas++:incorrectas++;
    })
    this.data.push(['Aprobación', 'Respuestas'])
    this.data.push(['Corretas',correctas])
    this.data.push(['Incorretas',incorrectas])
    this.loaded=true;
  }

  async onResize(event){
      if(this.pieChart!=null && this.pieChart.component !=null){
        this.pieChart.component.draw();
      }
  }


  consejo(){
    this.translate.get('CONSEJOS').subscribe(res=>{
      if(this.ficha.calificacion===1){
        this.mensaje = res.good;
      }
      else{
          if(this.ficha.calificacion>=0.8){
              this.mensaje = res.soso;
          }
          else{
              this.mensaje = res.bad;
          }
      }
    })
  }

  

}
