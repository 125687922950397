import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contratista } from '../../class/contratista';
import { Ficha } from '../../class/ficha';
import { Trabajador } from '../../class/trabajador';
import { Project } from '../../_class/project';
import { ProjectInscription } from '../../_class/project-inscription';
import * as _ from "lodash"
import * as XLSX from 'xlsx';
import { isArray, isObject, trim } from 'lodash';
import { validate, } from 'validate.js';
import { ToastrService } from 'ngx-toastr';

type AOA = any[][];

@Component({
  selector: 'app-inscription-create',
  templateUrl: './inscription-create.component.html',
  styleUrls: ['./inscription-create.component.scss']
})
export class InscriptionCreateComponent implements OnInit {

  @Input() contratistas: Contratista[]
  @Input() projects: Project[];

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  model: ProjectInscription;
  hasError = false;

  constructor(public toast: ToastrService) { }

  models: ProjectInscription[] = []

  ngOnInit(): void {
    this.model = {
      proId: null,
      status: 1,
      content: {
        ficha: {
          trabajador: new Trabajador(),
          params: {
            proId: null,
            sede: null,
            curso: null
          }
        }
      }
    }
  }

  // async onFile([file]: File[]) {
  //   try {
  //     var dataPromise = new Promise<any[]>((resolve, reject) => {
  //       papaparse.parse(
  //         file, {
  //         header: true,
  //         skipEmptyLines: true,
  //         complete: ({ data }) => { resolve(data); },
  //         error: reject
  //       }
  //       )
  //     })
  //     var data = await dataPromise;
  //     this.models = data.map(({ curso: nombreCurso, dni: rut, primeiro: nombre, segundo: paterno, correspondência: mail, campus: sede, idcurso: curso }) => {
  //       var project = this.findProject({ header: nombreCurso });
  //       var { id: proId } = project
  //       return {
  //         proId,
  //         status: 1,
  //         content: {
  //           ficha: {
  //             trabajador: {
  //               rut,
  //               nombre,
  //               paterno,
  //               mail,
  //             },
  //             params: {
  //               proId,
  //               sede,
  //               curso
  //             }
  //           }
  //         }
  //       }
  //     })
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  downloadExcel() {
    const data: AOA = [
      ["CPF", "nome", "sobrenome", "email", "curso", "idcurso", "local"],
      ["11111111", "test", "test", "test@test.com", "Trabalhe na Sala Elétrica", "Q001", "DR/AC"]
    ];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const fileName: string = 'esquadrão senai.xlsx';
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data senai');
    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  constraints = {
    "proId": {
      presence: {
        message: "^É necessária uma simulação válida"
      },
      numericality: true
    },
    "status": {
      presence: true,
      numericality: true
    },
    "content.ficha.trabajador.rut": {
      presence: {
        message: "^A identidade do aluno é necessária"
      },
    },
    "content.ficha.trabajador.nombre": {
      exclusion: {
        within: ["test"],
        message: "^Registro de teste"
      }
    },
    "content.ficha.trabajador.mail": {
      presence: true,
      email: {
        message: "^É necessário um e-mail válido"
      }
    }
  };

  getErrors(item: ProjectInscription) {
    try {
      let result = validate(item, this.constraints, { format: "flat", fullMessages: true, })
      if (result)
        throw (result)
      else
        return ["OK"]
    } catch (error) {
      if (isObject(error)) {
        return error;
      } else {
        return ["ERRO"]
      }
    }
  }

  async onFile([file]: File[]) {

    try {
      /**
       * Convertir archivo a datos
       */
      var dataPromise = new Promise((resolve, reject) => {
        try {
          let workBook = null;
          let jsonData = null;
          const reader = new FileReader();
          reader.onerror = reject;
          reader.onload = (event) => {
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            let sheetname = workBook.SheetNames[0];
            let sheet = workBook.Sheets[sheetname];
            jsonData = XLSX.utils.sheet_to_json(sheet);
            // jsonData = workBook.SheetNames.reduce((initial, name) => {
            //   const sheet = workBook.Sheets[name];
            //   initial[name] = XLSX.utils.sheet_to_json(sheet);
            //   return initial;
            // }, {});
            resolve(jsonData);
          }
          reader.readAsBinaryString(file);
        } catch (error) {
          reject(error)
        }
      })

      var data = await dataPromise;

      if (isArray(data)) {
        //   this.models = data.map(({ curso: nombreCurso, cc: rut, nombre, paterno, materno, email: mail, sede, codigo: curso }) => {

        this.models = data.map(({ curso: nombreCurso, CPF: rut, nome: nombre, sobrenome: paterno, email: mail, local: sede, idcurso: curso }) => {
          var project = this.findProject({ header: trim(nombreCurso) });
          var inscription: ProjectInscription = {
            proId: project?.id,
            status: 1,
            content: {
              ficha: {
                trabajador: {
                  rut: rut ? trim(`${rut}`) : null,
                  nombre: trim(nombre),
                  paterno: trim(paterno),
                  mail: trim(mail)
                },
                params: {
                  proId: project?.id,
                  sede: trim(sede),
                  curso: trim(curso)
                }
              }
            }
          }
          return inscription;
        })

        this.hasError = this.models.reduce((x, y) => {
          if (x) {
            return x;
          } else {
            return validate(y, this.constraints, { format: "flat", fullMessages: false }) !== undefined
          }
        }, false)
        if (this.hasError) {
          this.toast.warning("Erros foram encontrados, por favor, verifique o status.");
        } else {
          this.toast.success("Carregado com sucesso.");
        }

      }
    } catch (error) {
      this.toast.error("Ocorreu um problema.")
      console.error(error)
    }
  }

  findProject(criteria) {
    return _(this.projects).find(criteria);
  }
}
