import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Contratista } from '../../class/contratista';
import { Evaluacion } from '../../class/evaluacion';
import _ from "lodash-es";
import { Project } from '../../_class/project';
import { translate } from '@angular/localize/src/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inscription-search',
  templateUrl: './inscription-search.component.html',
  styleUrls: ['./inscription-search.component.scss']
})
export class InscriptionSearchComponent implements OnInit {
  @Input() projects: Project[]

  @Output() search: EventEmitter<any> = new EventEmitter();

  cedula: string
  nombre: string
  paterno: string
  materno: string
  status: string
  sede: string
  conId: number
  proId: number
  curso: number
  from: NgbDate
  to: NgbDate

  stateList = [{key:'1',label:'Ativo'},{key:'0',label:'Inativo'}];
  
  constructor(
    private translate:TranslateService
  ) {
    this.translate.get("FORM-OPTIONS").subscribe(res=>{
      this.stateList[0].label = res.active;
      this.stateList[1].label = res.inactive;
    })
   }

  ngOnInit(): void {
  }


  onSearch() {
    var criteria = {
      cedula: this.cedula,
      nombre: this.nombre,
      paterno: this.paterno,
      status: this.status,
      conId: this.conId,
      curso: this.curso,
      sede: this.sede,
      proId: this.proId,
      from: this.from,
      to: this.to
    }

    this.search.emit(_(criteria).pickBy(_.identity).value());
  }
}
