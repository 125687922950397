<div class="container-fluid">
  <div class="row">
    <div class="col mt-4">
      <img src="/assets/logo-senai1.png" height="100" class="rounded float-left" alt="...">
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-sm navbar-dark bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="[]">{{'NAVBAR.title' | translate}}</a>
    <button (click)="isMenuCollapsed = !isMenuCollapsed" class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-start" [ngbCollapse]="isMenuCollapsed" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/reportes']">
            <fa-icon [icon]=" ['fas','clipboard']"></fa-icon> {{'NAVBAR.reports' | translate}}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/inscriptions']">
            <fa-icon [icon]=" ['fas','users']"></fa-icon> {{'NAVBAR.inscriptions' | translate}}
          </a>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse justify-content-end" [ngbCollapse]="isMenuCollapsed" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item" ngbDropdown>
          <a class="nav-link" ngbDropdownToggle id="navbarDropdown2" role="button">
            <fa-icon [icon]="['fas','user']"></fa-icon> {{username}}
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem [routerLink]="" (click)="open(userEdit)">
              <fa-icon [icon]="['fas','user-edit']"></fa-icon> {{'NAVBAR.profile' | translate}}
            </a>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem [routerLink]="" (click)="logout()">
              <fa-icon [icon]="['fas','sign-out-alt']"></fa-icon> {{'NAVBAR.logout' | translate}}
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #userEdit>
  <app-edit-user-modal [user]="user" (cerrar)="close()" (guardar)="guardar($event)"></app-edit-user-modal>
</ng-template>