<div class="modal-container">
    <div class="modal-header">
        <h3 class="modal-title">{{"FORM-EDIT-WORKER.title"|translate}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="cerrar.emit()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form #form = "ngForm">
        <div class="modal-body">
            <div class="col-12">
                <small class="text-muted">{{"FORM-EDIT-WORKER.fields-with"|translate}} <span class="text-danger">*</span> {{"FORM-EDIT-WORKER.are-required"|translate}}</small>
            </div>
            <fieldset class="mb-3">
                <legend class="w-auto">{{"FORM-EDIT-WORKER.title-report"|translate}}</legend>
                <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
                    <label for="cc">{{"FORM-EDIT-WORKER.dni"|translate}}</label>
                    <input placeholder="{{'FORM-EDIT-WORKER.placeholder-dni'|translate}}" class="form-control" id="cc" name="cc" disabled [(ngModel)]="trabajador.rut">
                </div>
                <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
                    <label for="nombre">{{"FORM-EDIT-WORKER.name"|translate}}</label>
                    <input placeholder="{{'FORM-EDIT-WORKER.placeholder-name'|translate}}" class="form-control" id="nombre" name="nombre" [(ngModel)]="trabajador.nombre">
                </div>
                <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
                    <label for="paterno">{{"FORM-EDIT-WORKER.first-lastname"|translate}}</label>
                    <input placeholder="{{'FORM-EDIT-WORKER.placeholder-first-lastname'|translate}}" class="form-control" id="paterno" name="paterno" [(ngModel)]="trabajador.paterno">
                </div>
                <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
                    <label for="mail">{{"FORM-EDIT-WORKER.mail"|translate}}</label>
                    <input placeholder="{{'FORM-EDIT-WORKER.placeholder-mail'|translate}}" type="mail" class="form-control" id="mail" name="mail" [(ngModel)]="trabajador.mail" #email="ngModel"
                    email pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                    <small style="color:red" *ngIf="email.value!=='' && email.touched && email.errors">E-mail no valido</small>
                </div>
                <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
                    <label for="nacimiento">{{"FORM-EDIT-WORKER.birthday"|translate}}</label>
                    <input placeholder="{{'FORM-EDIT-WORKER.placeholder-birthday'|translate}}" type="date" class="form-control" id="nacimiento" name="nacimiento" [(ngModel)]="trabajador.nacimiento">
                </div>
            </fieldset>
            <fieldset class="mb-3">
                <legend class="w-auto">{{"FORM-EDIT-WORKER.title-report"|translate}}</legend>
                <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
                    <label for="sedeForm">{{"FORM-EDIT-WORKER.sede"|translate}}</label>
                    <!-- <input class="form-control" type="text" name="sedeForm" id="sedeForm" [(ngModel)]="params.sede"> -->
                    <select name="sedeForm" id="sedeForm" [(ngModel)]="params.sede" class="form-control">
                        <option></option>
                        <option value="DR/AC">DR/AC</option>
                        <option value="DR/DF">DR/DF</option>
                        <option value="DR/SE">DR/SE</option>
                    </select>
                </div>
                <div class="col-xl-4 col-md-6 col-sm-12 float-left mb-2 mt-2">
                    <label for="curseForm">{{"FORM-EDIT-WORKER.idcurso"|translate}}</label>
                    <input class="form-control" type="text" name="curseForm" id="curseForm" [(ngModel)]="params.curso">
                </div>
            </fieldset>
        </div>
        <div class="modal-footer">
            <div class="d-flex">
                <button [disabled]="form.invalid" class="btn btn-primary justify-content-end" type="submit" (click)="verificar()">Guardar</button>
            </div>
        </div>
    </form>
</div>