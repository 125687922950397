<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{"FORM-CREATE-INSCRIPTION.title"|translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form #formInscription="ngForm">
        <div class="col-12">
            <small class="text-muted">{{"FORM-EDIT-WORKER.fields-with"|translate}} <span class="text-danger">*</span>
                {{"FORM-EDIT-WORKER.are-required"|translate}}</small>
        </div>
        <fieldset class="mb-3">
            <legend>{{"FORM-CREATE-INSCRIPTION.title-personal"|translate}}</legend>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="traRut">{{"FORM-CREATE-INSCRIPTION.dni"|translate}}</label>
                    <input type="text" class="form-control" id="traRut" name="traRut"
                        placeholder="{{'FORM-CREATE-INSCRIPTION.placeholder-dni'|translate}}"
                        [(ngModel)]="model.content.ficha.trabajador.rut">
                </div>
                <div class="form-group col-md-6">
                    <label for="email">{{"FORM-CREATE-INSCRIPTION.mail"|translate}}</label>
                    <input type="email" class="form-control" id="email" name="email"
                        [placeholder]="'FORM-CREATE-INSCRIPTION.placeholder-mail'|translate"
                        [(ngModel)]="model.content.ficha.trabajador.mail">
                </div>
                <div class="form-group col-md-4">
                    <label for="traNombre">{{"FORM-CREATE-INSCRIPTION.name"|translate}}</label>
                    <input type="text" class="form-control" id="traNombre" name="traNombre"
                        placeholder="{{'FORM-CREATE-INSCRIPTION.placeholder-name'|translate}}"
                        [(ngModel)]="model.content.ficha.trabajador.nombre">
                </div>
                <div class="form-group col-md-4">
                    <label for="traPaterno">{{"FORM-CREATE-INSCRIPTION.first-lastname"|translate}}</label>
                    <input type="text" class="form-control" id="traPaterno" name="traPaterno"
                        placeholder="{{'FORM-CREATE-INSCRIPTION.placeholder-first-lastname'|translate}}"
                        [(ngModel)]="model.content.ficha.trabajador.paterno">
                </div>
            </div>
        </fieldset>
        <fieldset class="mb-3">
            <legend>{{"FORM-CREATE-INSCRIPTION.title-simulation"|translate}}</legend>

            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="projecto">{{"FORM-CREATE-INSCRIPTION.simulation"|translate}} <span
                            class="text-danger">*</span></label>
                    <ng-select [(ngModel)]="model.content.ficha.params.proId" [(ngModel)]="model.proId" name="projecto"
                        placeholder="{{'FORM-CREATE-INSCRIPTION.placeholder-simulation'|translate}}" required
                        #projecto="ngModel">
                        <ng-option *ngFor="let model of projects" [value]="model.id">
                            {{model.header}}</ng-option>
                    </ng-select>
                </div>

                <div class="form-group col-md-6">
                    <label for="parSede">{{"FORM-CREATE-INSCRIPTION.sede"|translate}}</label>
                    <!-- <input type="text" class="form-control" id="parSede" name="parSede"
                        placeholder="{{'FORM-CREATE-INSCRIPTION.placeholder-sede'|translate}}" [(ngModel)]="model.content.ficha.params.sede"> -->
                    <select name="parSede" id="parSede" [(ngModel)]="model.content.ficha.params.sede"
                        class="form-control">
                        <option></option>
                        <option value="DR/AC">DR/AC</option>
                        <option value="DR/DF">DR/DF</option>
                        <option value="DR/SE">DR/SE</option>
                    </select>
                </div>

                <div class="form-group col-md-6">
                    <label for="parCurso">{{"FORM-CREATE-INSCRIPTION.idcurso"|translate}}</label>
                    <input type="text" class="form-control" id="parCurso" name="parCurso"
                        placeholder="{{'FORM-CREATE-INSCRIPTION.placeholder-idcurso'|translate}}"
                        [(ngModel)]="model.content.ficha.params.curso">
                </div>
            </div>
        </fieldset>
        <fieldset>
            <legend>{{"FORM-CREATE-INSCRIPTION.masive-charge"|translate}}</legend>
            <div class="form-row">
                <div class="form-grou col">
                    <button class="btn btn-success btn-block mb-2" (click)="downloadExcel()">
                        <fa-icon [icon]="['fas','file-excel']"></fa-icon> Baixar modelo
                    </button>
                </div>
                <div class="form-group col-md-9">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="exampleInputFile"
                            (change)="onFile($event.target.files)" accept=".xlsx, .xls">
                        <label class="custom-file-label" for="exampleInputFile" data-browse="Selecionar">Selecione os
                            arquivo excel para upload em massa ... </label>
                    </div>
                </div>

            </div>
            <div class="table-responsive">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Aluno</th>
                            <th>Inscrição</th>
                            <th>Ocorrência</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-item [ngForOf]="models" let-i="index">
                            <tr>
                                <td rowspan="3">{{i+1}}</td>
                                <td><strong>{{"FORM-CREATE-INSCRIPTION.registro-general"|translate}}:</strong>
                                    {{item.content.ficha.trabajador.rut}}</td>
                                <td><strong>{{"FORM-CREATE-INSCRIPTION.curse"|translate}}:</strong> {{findProject({id:item.proId})?.header}}</td>
                                <td rowspan="3">
                                    <ul class="list-group list-group-flush" *ngFor="let error of getErrors(item)">
                                        <li class="list-group-item"
                                            [ngClass]="{'list-group-item-success':error=='OK','list-group-item-warning': error != 'OK'}">
                                            {{error}}</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>{{"FORM-CREATE-INSCRIPTION.name"|translate}}:</strong>
                                    {{item.content.ficha.trabajador.nombre}}
                                    {{item.content.ficha.trabajador.materno}}
                                    {{item.content.ficha.trabajador.paterno}}
                                </td>
                                <td><strong>{{"FORM-CREATE-INSCRIPTION.idcurso"|translate}}:</strong>
                                    {{item.content.ficha.params.curso}}</td>
                            </tr>
                            <tr>
                                <td><strong>{{"FORM-CREATE-INSCRIPTION.mail"|translate}}:</strong>
                                    {{item.content.ficha.trabajador.mail}}</td>
                                <td><strong>{{"FORM-CREATE-INSCRIPTION.sede"|translate}}:</strong>
                                    {{item.content.ficha.params.sede}}</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </fieldset>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="hasError"
        (click)="save.emit(models.length>0?models:[model])">{{"BUTTON.inscribir"|translate}}</button>
</div>