<div class="card mb-3">
    <div class="card-body">
        <h5 class="card-title">{{"FORM-SEARCH-REPORT.title"|translate}}</h5>
        <form #search="ngForm" (ngSubmit)="submit()">
            <div class="form-row mb-3">
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="rutSearch">{{"FORM-SEARCH-REPORT.dni"|translate}}</label>
                    <input placeholder="{{'FORM-SEARCH-REPORT.placeholder-dni'|translate}}" class="form-control" type="text" id="rutSearch"
                        name="rutSearch" [(ngModel)]="fichaFilter.traRut">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="nombreSearch">{{"FORM-SEARCH-REPORT.name"|translate}}</label>
                    <input placeholder="{{'FORM-SEARCH-REPORT.placeholder-name'|translate}}" class="form-control" type="text" id="nombreSearch"
                        name="nombreSearch" [(ngModel)]="fichaFilter.traNombre">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="paternoSearch">{{"FORM-SEARCH-REPORT.first-lastname"|translate}}</label>
                    <input placeholder="{{'FORM-SEARCH-REPORT.placeholder-first-lastname'|translate}}" class="form-control" type="text"
                        id="paternoSearch" name="paternoSearch" [(ngModel)]="fichaFilter.traPaterno">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="mailSearch">{{"FORM-SEARCH-REPORT.mail"|translate}}</label>
                    <input placeholder="{{'FORM-SEARCH-REPORT.placeholder-mail'|translate}}" class="form-control" type="text" id="mailSearch"
                        name="mailSearch" [(ngModel)]="fichaFilter.traMail">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="sedeSearch">{{"FORM-SEARCH-REPORT.sede"|translate}}</label>
                    <!-- <input placeholder="{{'FORM-SEARCH-REPORT.placeholder-sede'|translate}}" class="form-control" type="text" id="sedeSearch"
                        name="sedeSearch" [(ngModel)]="fichaFilter.sede"> -->
                        <select name="sedeSearch" id="sedeSearch" [(ngModel)]="fichaFilter.sede" class="form-control">
                            <option></option>
                            <option value="DR/AC">DR/AC</option>
                            <option value="DR/DF">DR/DF</option>
                            <option value="DR/SE">DR/SE</option>
                        </select>
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="idCurso">{{"FORM-SEARCH-REPORT.idcurso"|translate}}</label>
                    <input placeholder="{{'FORM-SEARCH-REPORT.placeholder-curse'|translate}}" class="form-control" type="text" id="idCurso"
                        name="idCurso" [(ngModel)]="fichaFilter.curso">
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12">
                    <label for="evaluacionSearch">{{"FORM-SEARCH-REPORT.curse"|translate}}</label>
                    <select class="form-control" id="evaluacionSearch" name="evaluacionSearch"
                        [(ngModel)]="fichaFilter.evaId">
                        <option></option>
                        <option *ngFor="let evaluacion of evaluaciones" value="{{evaluacion.eva_id}}">
                            {{'EVALUATION.EVA_'+evaluacion.eva_id|translate}}</option>
                    </select>
                </div>
            </div>
            <button type="reset" class="btn btn-secondary  float-md-right" (click)="limpiar.emit({})">
                <fa-icon [icon]="['fas','eraser']"></fa-icon> {{"BUTTON.clean"|translate}}
            </button>
            <button type="submit" class="btn btn-primary mr-3 float-md-right">
                <fa-icon [icon]="['fas','search']"></fa-icon> {{"BUTTON.search"|translate}}
            </button>
        </form>
    </div>
</div>
