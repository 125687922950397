<div class="modal-container">
    <div class="modal-header">
        <h3 class="modal-title">{{"TABLE-REPORT.title-view-report"|translate}} {{ficha.fic_id}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="cerrar.emit()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 mb-2">
                <h5>{{"TABLE-REPORT.title-personal"|translate}}</h5>
                <table class="table table-bordered">
                    <tr>
                        <th>{{"TABLE-REPORT.student"|translate}}</th>
                        <td>{{ficha.trabajador?.nombre}} {{ficha.trabajador?.paterno}}</td>
                    </tr>
                    <tr>
                        <th>{{"TABLE-REPORT.dni"|translate}}</th>
                        <td>{{ficha.trabajador?.rut}}</td>
                    </tr>
                    <tr>
                        <th>{{"TABLE-REPORT.email"|translate}}</th>
                        <td>{{ficha.trabajador?.mail}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 mb-2">
                <h5>{{"TABLE-REPORT.title-report"|translate}}</h5>
                <table class="table table-bordered">
                    <thead>
                        <th>{{"TABLE-REPORT.sede"|translate}}</th>
                        <th>{{"TABLE-REPORT.idcurso"|translate}}</th>
                        <th>{{"TABLE-REPORT.curse"|translate}}</th>
                        <th>{{"TABLE-REPORT.date"|translate}}</th>
                        <th>{{"TABLE-REPORT.calification"|translate}}</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ficha.params?.data?.sede}}</td>
                            <td>{{ficha.params?.data?.curso}}</td>
                            <td>{{'EVALUATION.EVA_'+ficha.evaluacion?.eva_id|translate}}</td>
                            <td>{{ficha.creado | date:"dd-MM-yyyy HH:mm:ss"}}</td>
                            <td>{{ficha.calificacion |percent}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div class="row" *ngIf="data.length>0 loaded" (window:resize)="onResize($event)">
            <div class="col-12">
                <google-chart [data]="pieChart"></google-chart>
            </div>
        </div>
        <div class="col-12 mb-3 mt-3">
            <h5>{{"TABLE-REPORT.advice"|translate}}: </h5>
            <h6>{{mensaje}}</h6>
        </div>
    </div>
</div>