<!-- Headers -->
<div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
        <h1>{{"TABLE-REPORT.title-admin"|translate}}</h1>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2 d-flex justify-content-end">
        <div>
            <button class="btn btn-secondary mr-2 mb-1" (click)="reload()">
                <fa-icon [icon]="['fas','redo']"></fa-icon> {{"BUTTON.reload"|translate}}
            </button>
            <button class="btn btn-success mr-2 mb-1" (click)="downloadExcel()">
                <fa-icon [icon]="['fas','file-excel']"></fa-icon> {{"BUTTON.report"|translate}}
            </button>
            <button class="btn btn-primary mb-1" (click)="isSearchCollapsed = !isSearchCollapsed">
                <fa-icon [icon]="['fas','search']"></fa-icon> {{"BUTTON.advanced-search"|translate}}
            </button>
        </div>
    </div>
</div>

<!-- Búsqueda Avanzada -->
<div class="row collapse" [ngbCollapse]="isSearchCollapsed" id="advanceSearch">
    <div class="col-12">
        <app-fichas-search [fichaFilter]="fichaFilter" [contratistas]="contratistas" [evaluaciones]="evaluaciones"
            (search)="busquedaAvanzada()" (limpiar)="limpiar()"></app-fichas-search>
    </div>
</div>

<!-- Tabla de datos -->
<div class="row" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <th>N°</th>
                    <th scope="col" *ngFor="let item of sort.menu"
                        [ngClass]="{ 'text-primary': sort.name == item.sort }" [ngStyle]="{ cursor: 'pointer'}"
                        (click)="setOrder(item.sort)">
                        <fa-icon [icon]="iconOrder(item.sort)"></fa-icon> {{item.title}}
                    </th>
                    <th>{{"TABLE-REPORT.options"|translate}}</th>
                </thead>
                <tbody>
                    <tr *ngFor="let ficha of fichas; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{ficha.fic_id}}</td>
                        <td>{{ficha.params?.data?.sede}}</td>
                        <td>{{ficha.params?.data?.curso}}</td>
                        <td>{{"EVALUATION.EVA_"+ficha.evaluacion?.eva_id|translate}}</td>
                        <td style="white-space: nowrap;">{{ficha.trabajador?.rut}}</td>
                        <td>{{ficha.trabajador?.nombre}}</td>
                        <td>{{ficha.trabajador?.paterno}}</td>
                        <td>{{ficha.trabajador?.mail}}</td>
                        <td style="text-align: end;">{{ficha.calificacion | percent}}</td>
                        <td style="white-space: nowrap;">{{ficha.creado | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                        <td>
                            <div class="d-flex justify-content-around">
                                <button ngbTooltip="{{'BUTTON.view-report'|translate}}" (click)="open(reporte, ficha)"
                                    class="btn btn-sm btn-info ml-1 mr-1">
                                    <fa-icon [icon]="['fas','eye']"></fa-icon>
                                </button>
                                <button ngbTooltip="{{'BUTTON.edit'|translate}}" (click)="open(editar, ficha)"
                                    class="btn btn-sm btn-primary ml-1 mr-1">
                                    <fa-icon [icon]="['fas','user-edit']"></fa-icon>
                                </button>
                                <button ngbTooltip="{{'BUTTON.download-pdf'|translate}}" class="btn btn-sm btn-danger ml-1 mr-1"
                                    (click)="getPdf(ficha.fic_id)">
                                    <fa-icon [icon]="['fas','file-pdf']"></fa-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-btn-scroll></app-btn-scroll>
<!-- Modal Ver Reporte -->
<ng-template #reporte>
    <app-ficha-reporte [fichaTemp]="fichaTemp" (cerrar)="close()"></app-ficha-reporte>
</ng-template>

<!-- Modal Editar Trabajador -->
<ng-template #editar>
    <app-ficha-editar-trabajador [fichaTemp]="fichaTemp" [contratistas]="contratistas" (cerrar)="close()"
        (guardar)="guardar($event)"></app-ficha-editar-trabajador>
</ng-template>