<div class="card mb-3">
    <div class="card-body">
        <h5 class="card-title">{{"FORM-SEARCH-INSCRIPTION.title"|translate}}</h5>
        <form #formSearch="ngForm" (ngSubmit)="onSearch()">
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="cedula">{{"FORM-SEARCH-INSCRIPTION.dni"|translate}}</label>
                    <input type="text" class="form-control" id="cedula" name="cedula" [(ngModel)]="cedula"
                        placeholder="{{'FORM-SEARCH-INSCRIPTION.dni'|translate}}">
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationDefault04">{{"FORM-SEARCH-INSCRIPTION.start-date"|translate}}</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="from" ngbDatepicker
                            #dp="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button">
                                <fa-icon [icon]="['fas','calendar']"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationDefault04">{{"FORM-SEARCH-INSCRIPTION.end-date"|translate}}</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="to" ngbDatepicker
                            #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                <fa-icon [icon]="['fas','calendar']"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="nombre">{{"FORM-SEARCH-INSCRIPTION.name"|translate}}</label>
                    <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="nombre"
                        placeholder="{{'FORM-SEARCH-INSCRIPTION.placeholder-name'|translate}}">
                </div>
                <div class="col-md-4 mb-3">
                    <label for="paterno">{{"FORM-SEARCH-INSCRIPTION.first-lastname"|translate}}</label>
                    <input type="text" class="form-control" id="paterno" name="paterno" [(ngModel)]="paterno"
                        placeholder="{{'FORM-SEARCH-INSCRIPTION.placeholder-first-lastname'|translate}}">
                </div>
                <div class="col-md-3 mb-3">
                    <label for="sede">{{"FORM-SEARCH-INSCRIPTION.sede"|translate}}</label>
                    <!-- <input type="text" class="form-control" id="sede" name="sede" [(ngModel)]="sede"
                        placeholder="{{'FORM-SEARCH-INSCRIPTION.placeholder-sede'|translate}}"> -->
                    <select name="sede" id="sede" [(ngModel)]="sede" class="form-control">
                        <option></option>
                        <option value="DR/AC">DR/AC</option>
                        <option value="DR/DF">DR/DF</option>
                        <option value="DR/SE">DR/SE</option>
                    </select>
                </div>
                <!-- <div class="col-md-3 mb-3">
                    <label for="validationDefault04">{{"FORM-SEARCH-INSCRIPTION.state"|translate}}</label>
                    <ng-select id="estado" name="estado" [items]="stateList"
                        bindLabel="label" bindValue="key" [(ngModel)]="proId" placeholder="{{'FORM-SEARCH-INSCRIPTION.placeholder-state'|translate}}">
                    </ng-select>
                </div> -->
                <div class="col-md-3 mb-3">
                    <label for="validationDefault04">{{"FORM-SEARCH-INSCRIPTION.curse"|translate}}</label>
                    <ng-select id="project" name="project" [items]="projects" bindLabel="header" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" bindValue="id" [(ngModel)]="proId"
                        placeholder="{{'FORM-SEARCH-INSCRIPTION.placeholder-curse'|translate}}">
                    </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                    <label for="curCodigo">{{"FORM-SEARCH-INSCRIPTION.idcurso"|translate}}</label>
                    <input type="text" class="form-control" id="curCodigo" name="curCodigo" [(ngModel)]="curso"
                        placeholder="{{'FORM-SEARCH-INSCRIPTION.placeholder-idcurso'|translate}}">
                </div>

            </div>
            <button type="reset" class="btn btn-secondary  float-md-right" (click)="search.emit({})">
                <fa-icon [icon]="['fas','eraser']"></fa-icon> {{"BUTTON.clean"|translate}}
            </button>
            <button type="submit" class="btn btn-primary mr-3 float-md-right">
                <fa-icon [icon]="['fas','search']"></fa-icon> {{"BUTTON.search"|translate}}
            </button>
        </form>
    </div>
</div>