import { Component } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './_services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'senai-pr';
  lang = ['en', 'es-CL', 'es-CO', 'es-AR', 'es-PE', 'es', 'pt-BR'];

  constructor(
    public translate: TranslateService,
    public session: AuthService
  ) {
    translate.addLangs(this.lang);

    const location = localStorage['user.preference.location'] || translate.getBrowserCultureLang();
    translate.setDefaultLang('pt-BR');
    translate.use(this.lang.includes(location) ? location : 'pt-BR');

    this.session.onLoginSuccess.subscribe(
      (crededential) => {
        if (crededential.scope && crededential.scope.location) {
          if (localStorage['user.preference.location'] !== crededential.scope.location) {
            localStorage['user.preference.location'] = crededential.scope.location;
            translate.use(this.lang.includes(crededential.scope.location) ? crededential.scope.location : 'es-CL');
          }
        }
      }
    );
  }
}