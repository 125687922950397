import { Component, OnDestroy, OnInit } from '@angular/core';
import { Evaluacion } from '../../class/evaluacion';
import { Ficha } from '../../class/ficha';
import { FichaFilter } from '../../class/filter/ficha-filter';
import { Pagination } from '../../class/pagination';
import { AuthService } from '../../_services/auth.service';
import { senaiService, senaiState } from '../../_services/senai.service';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadorService } from '../../_services/trabajador.service';
import { Contratista } from '../../class/contratista';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../_services/report.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { FichaParamsService } from '../../_services/ficha-params.service';
import { FichaParams } from '../../class/ficha-params';
import { User } from 'projects/transversales/src/app/_class';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fichas-admin',
  templateUrl: './fichas-admin.component.html',
  styleUrls: ['./fichas-admin.component.scss']
})
export class FichasAdminComponent implements OnInit, OnDestroy {

  fichas: Ficha[];
  fichasShowed: Ficha[];
  loginRef: Subscription;
  evaluaciones: Evaluacion[];
  contratistas: Contratista[];
  fichaFilter: FichaFilter = new FichaFilter();
  isSearchCollapsed = true;
  fichaTemp: Ficha;
  modalRef: NgbModalRef;
  data: senaiState = null;
  toastrRef;
  page: Pagination = new Pagination({
    total: 1,
    page: 1,
    perPage: 50,
    sort: '-fic_id',
    expand: ['trabajador', 'params', 'evaluacion', 'alternativas', 'params']
  });

  // Toastr Messages
  toastr = {
    saving: "guardando...",
    updatedInfo: "información actualizada",
    searchInfo: "Información filtrada",
    cleanInfo: "Información re-establecida",
    dontsave: "no se guardaron los cambios",
    error: "error",
    generatingPdf: "generando PDF...",
    success: "exito",
    pdfSuccess: "PDF se ha generado exitosamente",
    fail: "ha ocurrido un error",
    internetConnection: "Ha ocurrido un problema, verifique la conexión a internet",
    downloadReport: "descargando reporte masivo...",
    reportSuccess: "se ha descargado reporte masivo correctamente"
  }
  //#region Sorteable Table
  sort = {
    name: null,
    order: true,
    menu: [
      { sort: 'ficId', title: 'N° Ficha' },
      { sort: 'sede', title: 'Sede' },
      { sort: 'curso', title: 'ID Curso' },
      { sort: 'evaId', title: 'Curso' },
      { sort: 'traRut', title: 'N° de Ciudadanía' },
      { sort: 'traNombre', title: 'Nombre' },
      { sort: 'traPaterno', title: 'Paterno' },
      { sort: 'traMail', title: 'Correo' },
      { sort: 'calificacion', title: 'Calificación' },
      { sort: 'creado', title: 'Fecha' }
    ]
  };

  toastrFlags = {
    reload: false,
    search: false,
    clean: false
  }

  constructor(
    private sessionService: AuthService,
    private senaiService: senaiService,
    private modal: NgbModal,
    private trabajadorService: TrabajadorService,
    private toastService: ToastrService,
    private reportService: ReportService,
    private translate: TranslateService,
    private fichaParamsService: FichaParamsService
  ) { }

  ngOnInit(): void {
    this.translateMessage();

    if (this.sessionService.isValid) {
      this.init();
    }
    this.loginRef = this.sessionService.onLoginSuccess.subscribe(({ name }) => {
      this.init();
    });
  }


  ngOnDestroy() {
    this.loginRef.unsubscribe();
  }

  async init() {
    this.getFichas();
    this.getEvaluaciones();
    this.getContratistas();
  }

  getFichas() {
    this.senaiService.GetFichas(this.page.params)
      .subscribe(data => {
        this.fichas = data.body
        this.page.Load(data.headers);
        if (this.toastrFlags.search) {
          this.toastService.info(this.toastr.searchInfo);
          this.toastrFlags.search = false;
        }
        else {
          if (this.toastrFlags.clean) {
            this.toastService.info(this.toastr.cleanInfo);
            this.toastrFlags.clean = false;
          } else {
            if (this.toastrFlags.reload) {
              this.toastService.info(this.toastr.updatedInfo);
              this.toastrFlags.reload = false;
            }
          }
        }
      })
  }

  getEvaluaciones() {
    this.senaiService.GetEvaluaciones()
      .subscribe(data => {
        this.evaluaciones = data.body;
      })
  }

  getContratistas() {
    this.senaiService.GetContratistas()
      .subscribe(response => {
        this.contratistas = response.body;
      })
  }

  setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop;
      this.sort.order = true;
    } else {
      this.sort.order = !this.sort.order;
    }
    this.page.page = 1;
    this.page.sort = this.sort.order ? this.sort.name : '-' + this.sort.name
    this.init()
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? ['fas', 'sort-down'] : ['fas', 'sort-up'];
    } else {
      return ['fas', 'sort'];
    }
  }

  busquedaAvanzada() {
    _.each(this.fichaFilter, (filter, key) => {
      if ((key === "ficId" || key === "evaId" || key ==="conId" || key ==="sede" || key === "idcurso")) {
        if (filter !== null && filter !== "") {
          this.page.params = this.page.params.set(`filter[${key}]`, filter.toString());
        }
        else {
          this.page.DelParams(`filter[${key}]`);
        }
      }
      else {
        if ((key !== "ficId" && key !== "evaId" && key !== "conId" && key !== "sede" && key !== "idcurso")) {
          if (filter !== null && filter !== "") {
            this.page.params = this.page.params.set(`filter[${key}][like]`, filter.toString());
          }
          else {
            this.page.DelParams(`filter[${key}][like]`);
          }
        }
        else {
          if (key === "sede" || key === "idcurso") {
            if (filter !== null && filter !== "") {
              this.page.params = this.page.params.set(`filter[params][${key}]`, filter.toString());
            }
            else {
              this.page.DelParams(`filter[params][${key}]`);
            }
          }
        }
      }
    })
    this.toastrFlags.search = true;
    this.getFichas();
  }

  limpiar() {
    this.page.params = new HttpParams();
    this.fichaFilter = new FichaFilter();
    this.toastrFlags.clean = true;
    this.getFichas();
  }
  open(content, ficha) {
    this.fichaTemp = ficha;
    this.modalRef = this.modal.open(content, { size: 'xl' });
  }

  close() {
    this.modalRef.close();
  }

  translateMessage() {
    this.translate.get('CONTROL-MESSAGES').subscribe(res => {
      this.toastr = {
        saving: res.saving,
        updatedInfo: res.updatedInfo,
        cleanInfo: res.cleanInfo,
        searchInfo: res.searchInfo,
        dontsave: res.dontsave,
        error: res.error,
        generatingPdf: res.generatingPdf,
        success: res.success,
        pdfSuccess: res.pdfSuccess,
        fail: res.fail,
        internetConnection: res.internetConnection,
        downloadReport: res.downloadReport,
        reportSuccess: res.reportSuccess,
      }
    })

    this.translate.get('TABLE-REPORT').subscribe((res) => {
      this.sort.menu = [
        { sort: 'ficId', title: `N° ${res.ficha}` },
        { sort: 'sede', title: res.sede },
        { sort: 'curso', title: res.idcurso },
        { sort: 'evaId', title: res.curse },
        { sort: 'traRut', title: res.dni },
        { sort: 'traNombre', title: res.name },
        { sort: 'traPaterno', title: res.firstlastname },
        { sort: 'traMail', title: res.email },
        { sort: 'calificacion', title: res.calification },
        { sort: 'creado', title: res.date }
      ]
    })
  }

  async guardar(event) {
    this.toastrRef = this.toastService.info(this.toastr.saving)
    const trabajador = event.trabajador;
    const ficha = event.ficha;
    const params = event.params
    // const contratista = event.contratista;
    // if(Object.keys(contratista).length>0 && contratista.id===undefined){
    //   const response = await this.contratistaService.getIdentity(event.contratista).toPromise();
    //   event.ficha.con_id = response.id;
    // }
    try {
      if (ficha.params !== null) {
        ficha.params.data = params;
        await Promise.all([
          this.trabajadorService.Update(trabajador.tra_id, trabajador).toPromise(),
          this.fichaParamsService.Update(ficha.params.id, ficha.params).toPromise()
          // this.fichaService.Update(ficha.fic_id,ficha).toPromise()
        ])
      }
      else {
        let newParams = new FichaParams();
        newParams.content = event.params;
        newParams.fic_id = ficha.fic_id;
        await Promise.all([
          this.trabajadorService.Update(trabajador.tra_id, trabajador).toPromise(),
          this.fichaParamsService.Create(newParams).toPromise()
        ])
      }
      this.modalRef.close();
      this.toastService.remove(this.toastrRef);
      this.toastService.success(this.toastr.updatedInfo);
      this.init();
    }
    catch {
      this.toastService.error(this.toastr.dontsave, this.toastr.error)
    }
  }

  getPdf(ficha) {
    const toastrRef = this.toastService.info(this.toastr.generatingPdf, 'PDF');
    this.reportService.getFichaById(ficha)
      .subscribe(
        response => {
          const a = document.createElement('a');
          const filePDF = new Blob([response], { type: 'application/pdf' });
          const fileURL = window.URL.createObjectURL(filePDF);
          a.href = fileURL;
          a.download = 'REP SEG ' + ficha + '.pdf';
          a.click();
          this.toastService.remove(toastrRef.toastId);
          this.toastService.success(this.toastr.pdfSuccess, 'PDF');
        }, () => {
          this.toastService.remove(toastrRef.toastId);
          this.toastService.error(this.toastr.fail, 'PDF');
        });
  }

  async downloadExcel() {
    try {
      const toastrRef = this.toastService.info(this.toastr.downloadReport, 'REPORTE');
      const response = await this.senaiService.GetReporteMasivo().toPromise()
      const date = moment().format('DD-MM-YYYY hh.mm.ss')
      FileSaver.saveAs(new Blob([response]), `Reporte senaii ${date}.xlsx`)
      this.toastService.remove(this.toastrRef)
      this.toastService.success(this.toastr.reportSuccess)
    } catch (error) {
      this.toastService.remove(this.toastrRef)
      this.toastService.warning(this.toastr.internetConnection, error.message)
    }
  }

  async onScroll() {
    if (this.fichas !== undefined && this.fichas.length < this.page.total) {
      this.page.page++;
      const { body, headers } = await this.senaiService.GetFichas(this.page.params).toPromise();
      this.page.Load(headers);
      this.fichas.push(...body);
    }
  }

  reload() {
    this.toastrFlags.reload = true;
    this.init();
  }

}
